import config from './config.js'
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/functions';

const productionConfig = {
	apiKey: 'AIzaSyAeFRr3dqM2i1oPV4LLPEcepSQ5XYHGLJk',
	authDomain: 'phramebooth.firebaseapp.com',
	databaseURL: 'https://phramebooth.firebaseio.com',
	projectId: 'phramebooth',
	storageBucket: 'phramebooth.appspot.com',
	messagingSenderId: '578441486865',
	appId: '1:578441486865:web:973b52392a1ba16a8d87b0',
	measurementId: 'G-VZXE26SM6K'
}


const sandBoxConfig = {
	apiKey: "AIzaSyDrKxxLw5vw5sZ9yRl8Ofut6WK0EfEXwwQ",
	authDomain: "phramebooth-sandbox.firebaseapp.com",
	databaseURL: "https://phramebooth-sandbox.firebaseio.com",
	projectId: "phramebooth-sandbox",
	storageBucket: "phramebooth-sandbox.appspot.com",
	messagingSenderId: "314264829084",
	appId: "1:314264829084:web:4ea60ecdb64852ab9d0159",
	measurementId: "G-V5R0HN1GR0"
};


// const firebaseConfig = sandBoxConfig;
const firebaseConfig = productionConfig;

class Firebase {
	authUser;
	constructor(app) {
		// export default !firebase.apps.length ? firebase.initializeApp(config) : firebase.app();

		!app.apps.length ? app.initializeApp(firebaseConfig) : app.app()

		/* Helper */

		this.emailAuthProvider = app.auth.EmailAuthProvider

		/* Firebase APIs */

		this.auth = app.auth()
		this.db = app.firestore()
		this.firestore = app.firestore;
		this.storage = app.storage();
		this.functions = app.functions();

		/* Social Sign In Method Provider */

		this.googleProvider = new app.auth.GoogleAuthProvider()
		this.facebookProvider = new app.auth.FacebookAuthProvider()
		this.twitterProvider = new app.auth.TwitterAuthProvider()
	}

	// *** Auth API ***
	doCreateUserWithEmailAndPassword = (email, password) =>
		this.auth.createUserWithEmailAndPassword(email, password);

	doSignInWithEmailAndPassword = (email, password) =>
		this.auth.signInWithEmailAndPassword(email, password);

	doSignInWithGoogle = () =>
		this.auth.signInWithPopup(this.googleProvider);

	doSignInWithFacebook = () =>
		this.auth.signInWithPopup(this.facebookProvider);

	doSignInWithTwitter = () =>
		this.auth.signInWithPopup(this.twitterProvider);

	doSignOut = () => this.auth.signOut();

	doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

	doSendEmailVerification = () =>
		this.auth.currentUser.sendEmailVerification({
			url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
		});

	doPasswordUpdate = password =>
		this.auth.currentUser.updatePassword(password);

	// *** Merge Auth and DB User API *** //

	onAuthUserListener = (next, fallback) =>
		this.auth.onAuthStateChanged(async (authUser) => {
			if (authUser) {
				let isAdmin = false;
				this.user(authUser.uid)
				this.authUser = authUser;
				let X = await this.db.collection("accessControl").doc(authUser.uid).get();
				if (X.exists) {
					let y = X.data().groups.filter(x => x === "admin");
					if (y && y.length === 1) {
						isAdmin = true;
					}
				}
				let refUser = await this.db.collection("/accounts/").doc(authUser.uid).get();
				if (refUser.exists) {
					authUser = {
						isAdmin: isAdmin,
						uid: authUser.uid,
						email: authUser.email,
						name: authUser.name,
						emailVerified: authUser.emailVerified,
						...refUser.data(),
						providerData: authUser.providerData,
					}
					next(authUser)
				}
			} else {
				fallback()
			}
		});


	// *** User API ***

	user = uid => this.db.doc(`users/${uid}`);

	users = () => this.db.doc('users');

	// *** Message API ***

	message = uid => this.db.doc(`messages/${uid}`);

	messages = () => this.db.doc('messages');
}

let firebase

function getFirebase(app, auth, database) {
	if (!firebase) {
		firebase = new Firebase(app, auth, database)
	}

	return firebase
}

export default getFirebase
