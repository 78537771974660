import React, { Component } from 'react'
import { ThemeContextProvider } from './themeContext'
import styled from 'styled-components'
import getFirebase, { FirebaseContext } from '../components/Firebase'
import withAuthentication from '../components/Session/withAuthentication'
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-daterangepicker/daterangepicker.css';
import './layout.css'
const LayoutContainer = styled.div`
display: flex;
flex-direction: column;
height: 100%;
background: ${props => props.theme.background};
color: ${props => props.theme.color};
`

class Layout extends Component {
	state = {
		firebase: null,
	};

	componentDidMount() {
		const app = import('firebase/app')
		const auth = import('firebase/auth')
		const database = import('firebase/firestore')

		Promise.all([app, auth, database]).then(values => {
			const firebase = getFirebase(values[0])
			this.setState({ firebase })
		})
	}

	render() {
		return (
			<ThemeContextProvider>
				<LayoutContainer>
					<div style={{ flex: '1 0 auto'}}>
						<FirebaseContext.Provider value={this.state.firebase}>
							<AppWithAuthentication {...this.props} />
						</FirebaseContext.Provider>
					</div>
				</LayoutContainer>
			</ThemeContextProvider>
		)
	}
}

const AppWithAuthentication = withAuthentication(({ children }) => (
	<>
		{children}
	</>
))

export default Layout
